import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { GlobalPositions } from 'buefy/types/helpers';

class AlertCenter {
  error(txt, props = {}) {
    const position: GlobalPositions = 'is-bottom-right';
    const params = Object.assign({
      message: txt,
      type: 'is-danger',
      position,
      actionText: null,
      duration: 5000
    }, props);
    return Snackbar.open(params);
  }

  info(txt, props = {}) {
    const position: GlobalPositions = 'is-top';
    const params = Object.assign({
      message: txt,
      type: 'is-success',
      position,
      actionText: null,
      duration: 2000
    }, props);
    return Snackbar.open(params);
  }
}

export const alertCenter = new AlertCenter();

export const AlertMixin = {
  methods: {
    $alertError(txt, props) {
      return alertCenter.error(txt, props);
    },
    $alertSuccess(txt, props) {
      return alertCenter.info(txt, props);
    }
  },
  async beforeRouteLeave(to, from, next) {
    next();
  }
};

window['alertCenter'] = alertCenter;

const initialState = () => ({
  dirty: false,
});

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setDirty(state) {
      state.dirty = true;
    },
    setClean(state) {
      state.dirty = false;
    }
  },
  actions: {
    setWindow({ state }, window) {
      window.addEventListener('beforeunload', (event) => {
        if (!state.dirty) return;
        event.preventDefault();
        return event.returnValue = 'You have unsaved data. Are you sure you want to exit?';
      }, {capture: true});
    }
  }
}

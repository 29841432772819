export default {
  async loadMessages(locale) {
    switch (locale) {
      case 'nl':
        return import('../i18n/nl.json');
      case 'en':
        return import('../i18n/en.json');
      case 'es':
        return import('../i18n/es.json');
      case 'fr':
        return import('../i18n/fr.json');
      case 'it':
        return import('../i18n/it.json');
      case 'de':
        return import('../i18n/de.json');
    }
  }
};

<template>
  <router-view :data-tst-page="dataTstPage" />
</template>
<script>
export default {
  name: 'MyRouterView',
  computed: {
    dataTstPage() {
      return this.$route.name;
    }
  }
};
</script>

export default {
  namespaced: true,
  state: {
    routeFilters: {}
  },
  mutations: {
    setRouteFilters(state, payload) {
      state.routeFilters[payload.name] = Object.assign(payload.filters);
    }
  }
};

/* eslint-disable camelcase */
import Vuex from 'vuex';
import Vue from 'vue';
import authentication from './modules/authentication.module';
import oauth from './modules/oauth.module';
import breadcrumb from './modules/breadcrumb.module';
import i18n from './modules/i18n.module';
import notification from './modules/notification.module';
import navigation from './modules/navigation.module';
import theme from './modules/theme.module';
import blocksave from './modules/blocksave.module';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  actions: {
    resetAll({ commit, dispatch }) {
      commit('authentication/reset');
      commit('oauth/reset');
      commit('breadcrumb/reset');
      //commit('user/reset');
    },
    init({ commit, dispatch }) {
      dispatch('blocksave/setWindow', window);
    }
  },
  modules: {
    authentication,
    breadcrumb,
    i18n,
    notification,
    navigation,
    theme,
    blocksave,
    oauth
  },
  plugins: []
});

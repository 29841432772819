<template>
  <nav class="navbar user-navbar is-fixed-top" role="navigation" aria-label="main navigation">
    <div class="container">
      <div class="navbar-brand">
        <router-link class="navbar-item" :to="{ name: 'templates' }">
          <img src="@/assets/images/main-logo.svg" height="42" width="124" class="logo" />
        </router-link>
      </div>

      <div class="navbar-menu">
        <router-link class="navbar-item" :to="{ name: 'dossiers', query: savedQuery('dossiers') }" :active="isActiveRoute('dossiers')" >
          <span :class="{ 'hidden-box': isActiveRoute('dossiers') }">
            <img v-svg-inline class="inline-icon" :src="require('@/assets/icons/navbar/dossiers.svg')" />
          </span>
          <span :class="{ 'hidden-box': !isActiveRoute('dossiers') }">
            <img v-svg-inline class="inline-icon" :src="require('@/assets/icons/navbar/dossiers_filled.svg')" />
          </span>
          {{ $t('dossier.dossiers') }}
        </router-link>
        <router-link class="navbar-item" v-if="!hasRole(['BASIC_USER'])" :to="{ name: 'templates', query: savedQuery('templates') }" :active="isActiveRoute('templates')">
          <span :class="{ 'hidden-box': isActiveRoute('templates') }">
            <img v-svg-inline class="inline-icon" :src="require('@/assets/icons/navbar/templates.svg')" />
          </span>
          <span :class="{ 'hidden-box': !isActiveRoute('templates') }">
            <img v-svg-inline class="inline-icon" :src="require('@/assets/icons/navbar/templates_filled.svg')" />
          </span>
          {{ $t('template.templates') }}
        </router-link>
        <router-link class="navbar-item" :to="{ name: 'contacts', query: savedQuery('contacts') }" :active="isActiveRoute('contacts')">
          <span :class="{ 'hidden-box': isActiveRoute('contacts') }">
            <img v-svg-inline class="inline-icon" :src="require('@/assets/icons/navbar/contacts.svg')" />
          </span>
          <span :class="{ 'hidden-box': !isActiveRoute('contacts') }">
            <img v-svg-inline class="inline-icon" :src="require('@/assets/icons/navbar/contacts_filled.svg')" />
          </span>
          {{ $t('contact.contacts') }}
        </router-link>
      </div>

      <div class="navbar-end">
        <span class="navbar-item is-mobile is-hidden-desktop">{{ user && user.name ? user.name : '?' }}</span>
        <a class="navbar-item p-0" v-if="hasRole(['ADMIN'])" tabindex="-1" @click="cycleTheme()">
          <span :class="{ 'hidden-box': theme === 'main' }">
            <img v-svg-inline class="theme-switch-icon inline-icon" :src="require('@/assets/icons/fa/fas/moon.svg')" />
          </span>
          <span :class="{ 'hidden-box': theme === 'main_dark' }">
            <img v-svg-inline class="theme-switch-icon inline-icon" :src="require('@/assets/icons/fa/fas/sun.svg')" />
          </span>
        </a>
        <router-link class="navbar-item" v-if="hasRole(['ADMIN'])" :to="{ name: 'notifications' }">
          <img v-svg-inline class="inline-icon" :src="require('@/assets/icons/navbar/notification.svg')" />
          <span v-if="notificationsCount > 0" class="notification-counter">{{ notificationsCount }}</span>
        </router-link>
        <div class="navbar-item has-dropdown navbar-user-dropdown" :class="{ 'is-active': userMenuActive }">
          <a class="navbar-link is-arrowless" :title="user.email" @click.prevent.stop="userMenuActive = !userMenuActive">
            <div>{{ initials }}</div>
          </a>
          <div class="navbar-dropdown">
            <router-link class="navbar-item" v-if="hasRole(['ADMIN'])" :to="{ name: 'auditlog' }">{{ $t('common.audit') }}</router-link>
            <router-link class="navbar-item" v-if="hasRole(['ROOT'])" :to="{ name: 'organizations' }">{{ $t('organization.organizations') }}</router-link>
            <router-link class="navbar-item" v-if="hasRole(['ADMIN'])" :to="{ name: 'users' }">{{ $t('user.users') }}</router-link>
            <router-link class="navbar-item" :to="{ name: 'settings' }">{{ $t('user.accountSettings') }}</router-link>
            <router-link class="navbar-item" :to="{ name: 'faq' }">{{ $t('navbar.faq') }}</router-link>
            <router-link class="navbar-item" :to="{ name: 'terms' }">{{ $t('navbar.terms') }}</router-link>
            <a class="navbar-item" :href="'mailto:' + CONTACT_MAIL">{{ $t('navbar.contactUs') }}</a>
            <router-link class="navbar-item" v-if="hasRole(['ADMIN'])" :to="{ name: 'platforminfo' }">{{ $t('navbar.platformInfo') }}</router-link>
            <router-link class="navbar-item" :to="{ name: 'logout' }">{{ $t('navbar.logOut') }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import NavigationMixin from '@/mixins/NavigationMixin';

export default {
  name: 'NavBar',
  mixins: [NavigationMixin],
  data() {
    return {
      userMenuActive: false,
      CONTACT_MAIL: import.meta.env.VITE_CONTACT_MAIL
    };
  },
  computed: {
    ...mapGetters({
      user: 'authentication/user',
      global_role: 'authentication/global_role'
    }),
    ...mapState('notification', ['notificationsCount']),
    ...mapState('theme', ['theme']),
    initials() {
      if (this.user && this.user.name) {
        return this.user.name.substr(0, 2).toUpperCase();
      } else if (this.user && this.user.firstname && this.user.lastname) {
        return this.user.firstname.substr(0, 1).toUpperCase() + this.user.lastname.substr(0, 1).toUpperCase();
      } else {
        return '?';
      }
    }
  },

  mounted() {
    document.addEventListener('click', (ev) => {
      this.userMenuActive = false;
    });
  },

  methods: {
    ...mapActions('theme', ['cycleTheme']),
    isActiveRoute(routeNames) {
      if (!Array.isArray(routeNames)) {
        routeNames = [routeNames];
      }
      return !!this.$route.matched.find(route => routeNames.find(name => name === route.name || (route.meta && route.meta.navbar === name)));
    }
  }
};
</script>
<style>
.navbar-user-dropdown {
  position: relative;
}
.navbar-user-dropdown img {
  position: absolute;
  top: 0;
  left: 0;
}
</style>

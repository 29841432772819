import Vue from 'vue';
import VueRouter from 'vue-router';

import MyRouterView from '@/layout/MyRouterView.vue';
import AdminNoSidebarLayout from '@/layout/AdminNoSidebarLayout.vue';
import store from '@/store/store';

Vue.use(VueRouter);

const ifNotAuthenticated = async (to, from, next) => {
  if (!store.getters['authentication/isAuthenticated']) {
    const accessToken = await store.dispatch('authentication/refreshToken');
    if (!accessToken) {
      next();
      return;
    }
  }
  next('/');
};

const ifAuthenticated = async (to, from, next) => {
  if (!store.getters['authentication/isAuthenticated']) {
    const accessToken = await store.dispatch('authentication/refreshToken');
    if (!accessToken) {
      const redirect = to.fullPath !== '/' ? to.fullPath : undefined;
      next({
        name: 'login',
        query: {
          redirect
        }
      });
      return;
    }
  }
  next();
};

const hasRole = (roles) => {
  return async (to, from, next) => {
    if (!store.getters['authentication/isAuthenticated']) {
      const accessToken = await store.dispatch('authentication/refreshToken');
      if (!accessToken) {
        const redirect = to.fullPath !== '/' ? to.fullPath : undefined;
        next({
          name: 'login',
          query: {
            redirect
          }
        });
        return;
      }
    }

    if (roles.indexOf(store.getters['authentication/global_role']) > -1) {
      if (store.getters['authentication/isAuthenticated']) {
        next();
        return;
      }
      const accessToken = await store.dispatch('authentication/refreshToken');
      if (accessToken) {
        next();
        return;
      }
    }

    next('/unauthorized');
  };
}

const router = new VueRouter({
  mode: ['/index.html'].indexOf(window.location.pathname) > -1 ? 'hash' : 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: '/test-error',
      name: 'test-error',
      meta: { lang_navbar: true, fullScreen: true },
      beforeEnter() {
        throw new Error('test-error');
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../modules/authentication/views/LoginForm.vue'),
      meta: {
        lang_navbar: true,
        fullScreen: true
      },
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/logout',
      name: 'logout',
      meta: {
        lang_navbar: true,
        fullScreen: true
      },
      component: () => import('../modules/authentication/views/LogoutForm.vue'),
    },
    {
      path: '/signup',
      name: 'signup',
      component: () => import('../modules/authentication/views/SignupForm.vue'),
      meta: {
        lang_navbar: true,
        fullScreen: true
      },
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/confirm-email',
      name: 'confirm-email',
      component: () => import('../modules/authentication/views/ConfirmEmail.vue'),
      meta: {
        lang_navbar: true,
        fullScreen: true
      }
    },
    {
      path: '/reset-password-request',
      name: 'reset-password-request',
      component: () => import('../modules/authentication/views/ResetPasswordRequest.vue'),
      meta: {
        lang_navbar: true,
        fullScreen: true
      },
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import('../modules/authentication/views/ResetPassword.vue'),
      meta: {
        lang_navbar: true,
        fullScreen: true
      },
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/invite',
      name: 'invite',
      component: () => import('../modules/authentication/views/InviteForm.vue'),
      async beforeEnter(to, from, next) {
        await store.dispatch('authentication/clearAuthentication');
        next();
      },
      meta: {
        lang_navbar: true,
        fullScreen: true
      }
    },
    /*{
      path: "/error",
      name: "error",
      component: ErrorView,
      meta: { layout: LAYOUT_DEFAULT }
    } */
    {
      path: '/oauth',
      component: AdminNoSidebarLayout,
      children: [
        {
          name: 'oauth_callback',
          path: ':client_name/callback',
          component: () => import('../modules/authentication/views/OAuthCallback.vue'),
          meta: {
            fullScreen: true
          }
        },
      ]
    },
    {
      path: '',
      component: AdminNoSidebarLayout,
      children: [
        {
          path: '/',
          name: 'home',
          async beforeEnter(to, from, next) {
            return next('/admin');
          }
        },
        {
          name: 'settings',
          path: 'settings',
          component: () => import('../modules/home/views/ViewSettings.vue'),
          beforeEnter: ifAuthenticated
        },
        { //
          name: 'platforminfo',
          path: 'platforminfo',
          component: () => import('../modules/platform/views/PlatformInfo.vue'),
          beforeEnter: hasRole(['ADMIN']),
        },
        {
          name: 'faq',
          path: 'faq',
          component: () => import('../modules/home/views/FaqView.vue')
        },
        {
          name: 'terms',
          path: 'terms',
          component: () => import('../modules/home/views/TermsView.vue')
        },
        {
          name: 'imprint',
          path: 'imprint',
          component: () => import('../modules/home/views/ImprintView.vue')
        },
        {
          name: 'privacy',
          path: 'privacy',
          component: () => import('../modules/home/views/PrivacyView.vue')
        }
      ]
    },
    {
      path: '/admin',
      name: 'admin',
      component: AdminNoSidebarLayout,
      redirect: '/admin/files',
      meta: {},
      beforeEnter: hasRole(['ADMIN', 'USER', 'USER_VIEW', 'BASIC_USER']),
      children: [
        {
          name: 'auditlog',
          path: 'audit-log',
          component: () => import('../modules/audit-log/views/ListAuditLog.vue')
        },
        {
          name: 'notifications',
          path: 'notifications',
          component: () => import('../modules/home/views/ViewNotifications.vue')
        },
        {
          path: 'templates',
          component: MyRouterView,
          children: [
            {
              name: 'templates',
              path: '',
              component: () => import('../modules/template/views/ListTemplates.vue'),
              meta: {
                navbar: 'templates'
              }
            },
            {
              name: 'newtemplate',
              path: 'new',
              component: () => import('../modules/template/views/CreateTemplate.vue'),
              meta: {
                navbar: 'templates'
              }
            },
            {
              name: 'edittemplate',
              path: ':id/edit',
              component: () => import('../modules/template/views/EditTemplate.vue'),
              meta: {
                navbar: 'templates'
              }
            },
            {
              name: 'template',
              path: ':id',
              component: () => import('../modules/template/views/ViewTemplate.vue'),
              meta: {
                navbar: 'templates'
              }
            }
          ]
        },
        {
          path: 'files',
          component: MyRouterView,
          children: [
            {
              name: 'dossiers',
              path: '',
              component: () => import('../modules/dossier/views/ListDossiers.vue'),
              meta: {
                navbar: 'dossiers'
              }
            },
            {
              name: 'newdossier',
              path: 'new',
              component: () => import('../modules/dossier/views/CreateDossier.vue'),
              meta: {
                navbar: 'dossiers'
              }
            },
            {
              path: '',
              component: MyRouterView,
              children: [
                {
                  name: 'dossier',
                  path: ':id',
                  component: () => import('../modules/dossier/views/ViewDossier.vue'),
                  meta: {
                    navbar: 'dossiers'
                  }
                }
              ]
            }
          ]
        },
        {
          path: 'contacts',
          component: MyRouterView,
          children: [
            {
              name: 'contacts',
              path: '',
              component: () => import('../modules/contact/views/ListContacts.vue'),
              meta: {
                navbar: 'contacts'
              }
            },
            {
              name: 'newcontact',
              path: 'new',
              component: () => import('../modules/contact/views/CreateContact.vue'),
              meta: {
                navbar: 'contacts'
              }
            },
            {
              name: 'contact',
              path: ':id',
              component: () => import('../modules/contact/views/ViewContact.vue'),
              meta: {
                navbar: 'contacts'
              }
            }
          ]
        },
        {
          path: 'users',
          component: MyRouterView,
          children: [
            {
              name: 'users',
              path: '',
              component: () => import('../modules/user/views/ListUsers.vue')
            },
            {
              name: 'newuser',
              path: 'new',
              component: () => import('../modules/user/views/CreateUser.vue')
            },
            {
              name: 'user',
              path: ':id',
              component: () => import('../modules/user/views/ViewUser.vue')
            }
          ]
        },
        {
          path: 'organizations',
          component: MyRouterView,
          children: [
            {
              name: 'organizations',
              path: '',
              component: () => import('../modules/organization/views/ListOrganizations.vue')
            },
            {
              name: 'neworganization',
              path: 'new',
              component: () => import('../modules/organization/views/CreateOrganization.vue')
            },
            {
              name: 'organization',
              path: ':id',
              component: () => import('../modules/organization/views/ViewOrganization.vue')
            }
          ]
        },
        {
          path: 'importexport',
          component: MyRouterView,
          children: [
            {
              name: 'importexport',
              path: '',
              component: () => import('../modules/organization/views/ImportExport.vue')
            }
          ]
        }
      ]
    },
    {
      name: 'unauthorized',
      path: '/unauthorized',
      component: () => import('../shared/UnauthorizedView.vue'),
      meta: {}
    },
    {
      name: 'not-matched',
      path: '*',
      component: () => import('../shared/NotFoundView.vue'),
      meta: {}
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  if (store.state.blocksave.dirty) {
    const value = window.confirm('You have unsaved data. Are you sure you want to exit?');
    if (!value) {
      return next(false);
    }
    store.commit('blocksave/setClean');
  }
  if (to.query.token && !store.state.authentication.accessToken && to.name !== 'confirm-email') {
    await store.dispatch('authentication/login', { token: to.query.token });
  }

  next();
});

router.afterEach(to => {
  if (to.meta.background) {
    document.body.setAttribute('data-background', to.meta.background);
  } else {
    document.body.removeAttribute('data-background');
  }
});

export default router;

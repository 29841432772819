import axios from 'axios';
import store from '../store/store';
import { get } from 'lodash';
import { alertCenter } from '@/alert';
import { API_LEVEL } from '@/utils/enums';
import * as Sentry from "@sentry/vue";

// Set up axios
const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: import.meta.env.VITE_API_BASE_URL, // Base url injected from environment-config
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 120000 // Request timeout
});

axiosInstance.interceptors.request.use(
  config => {
    // If it doesn't start with http it means is our api, so add the authorization header
    if (!config.url.startsWith('http') && store.state.authentication.accessToken) {
      config.headers['Authorization'] = `Bearer ${store.state.authentication.accessToken}`;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.request.use(
  config => {
    // If it doesn't start with http it means is our api, so add the authorization header
    if (!config.url.startsWith('http')) {
      const lang = store.state.i18n.lang || 'en';
      config.headers['locale'] = lang;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export class HttpError extends Error {
  public isHttpError: boolean;
  public status: number;
  public validateErrors: any;

  constructor(message, status: number) {
    super(message);
    this.isHttpError = true;
    this.status = status;
  }
}

async function http(props) {
  try {
    const response = await axiosInstance(props);

    if (response.headers['api-level'] && response.headers['api-level'] > API_LEVEL) {
      alertCenter.error('WebPage reload required.', {
        actionText: 'Reload',
        duration: 600000,
        onAction: () => {
          window.location.reload();
        }
      });
    }

    const method = props?.method || '';
    switch (method.toLowerCase()) {
      case 'post':
        if (response.status === 201) {
/*
          alertCenter.info('Created', {
            type: 'is-info',
            position: 'is-bottom-right',
            duration: 2000
          });
*/
        }
        break;
      case 'delete':
        if (response.status === 204) {
/*
          alertCenter.info('Deleted', {
            type: 'is-info',
            position: 'is-bottom-right',
            duration: 2000
          });
*/
        }
        break;
    }

    return response;
  } catch (error) {
    if (429 === error.response?.status) {
      alertCenter.error('Rate limit exceeded');
      throw new HttpError('Rate limit exceeded', 429);
    }

    const configError = error.config ? ' (' + error.config.method.toUpperCase() + ' ' + error.config.url + ')' : '';
    const unknownErrorMessage = error.message ? error.message + configError : 'Unknown Error: ' + error.toString();
    let message = unknownErrorMessage;
    if (error.response && error.response.data && error.response.data.message) {
      message = error.response.data.message;
    } else if (error.response && error.response.data && error.response.data.error && error.response.data.error.message) {
      message = error.response.data.error.message;
    } else {
      console.error(error);
    }

    // Unauthorized: jwt expired
    if (message === 'jwt expired' || message === 'jwt malformed') {
      try {
        const accessToken = await store.dispatch('authentication/refreshToken');
        if (accessToken) {
          error.response.config.headers['Authorization'] = `Bearer ${store.state.authentication.accessToken}`;
        }
        return await axios(error.response.config);
      } catch (err) {
        Sentry.captureException(err);
        if (props) {
          Sentry.captureMessage('Session has timed out for ' + props.method + ' ' + props.url + ' ' + JSON.stringify(props.data));
        }
        alertCenter.error('Your session has timed out');
        await store.dispatch('authentication/logout', { skipCall: true });
        throw new HttpError('Your session has timed out', 490);
      }
    }

    if (error.response && error.response.status === 404) {
      throw new HttpError(message + ` [${error.request.responseURL}]`, 404);
    }
    if (error.response && error.response.status === 401) {
      throw new HttpError(message + ` [${error.request.responseURL}]`, 401);
    }
    if (error.response && error.response.status === 400) {
      throw new HttpError(message + ` [${error.request.responseURL}]`, 400);
    }

    let errorMessage = get(
      error,
      ['response', 'data', 'message'],
      get(error, ['response', 'data', 'stack', 'message'], get(error, ['response', 'data', 'error', 'message'], get(error, ['message'], 'Something went wrong, please try again.')))
    );
    if (error.response && error.response.data) {
      const data = error.response.data;
      if (data.error && data.error.message) {
        errorMessage = error.response.data.error.message;
      }
    }

    const throwError = new HttpError(errorMessage, 400);
    if (message === 'Validation error') {
      if (error.response.data.error.data) {
        throwError.validateErrors = error.response.data.error.data;
      }
    }

    throw throwError;
  }
}

export default http;

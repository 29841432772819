const initialState = () => ({
  theme: 'main'
});

const THEMES = ['main', 'main_dark'];

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    initTheme({ dispatch }) {
      const theme = localStorage.getItem('theme') || 'main';
      dispatch('changeTheme', theme);
    },
    async changeTheme({ state, commit }, theme) {
      commit('setTheme', theme);
      const darkThemeStyleEl = document.getElementById('darkThemeStyle');
      const mainThemeStyleEl = document.getElementById('mainThemeStyle');
      switch (theme) {
        case 'main':
          mainThemeStyleEl.setAttribute('media', 'all');
          darkThemeStyleEl.setAttribute('media', 'none');
          break;
        case 'main_dark':
          mainThemeStyleEl.setAttribute('media', 'none');
          darkThemeStyleEl.setAttribute('media', 'all');
          break;
      }
    },
    async cycleTheme({ state, dispatch }) {
      let idx = THEMES.indexOf(state.theme);
      idx++;
      if (idx >= THEMES.length) {
        idx = 0;
      }
      dispatch('changeTheme', THEMES[idx]);
    }
  },
  mutations: {
    setTheme(state, theme) {
      state.theme = theme;
      localStorage.setItem('theme', theme);
    }
  }
};

import http from '../utils/http';

export default {
  async login(email, password) {
    const data = {
      email,
      password
    };

    return http({
      url: '/auth/local/login',
      method: 'post',
      data
    });
  },

  async loginByToken(token) {
    const data = {
      token
    };

    return http({
      url: '/auth/local/login',
      method: 'post',
      data
    });
  },

  async signup(firstname, lastname, email, password, agree_to_tc_pp, language, captcha) {
    const data = {
      firstname,
      lastname,
      email,
      password,
      agree_to_tc_pp,
      language,
      captcha
    };
    return http({
      url: '/auth/local/signup',
      method: 'post',
      data
    });
  },

  async logout() {
    return http({
      url: '/auth/local/logout',
      method: 'post'
    });
  },

  async confirmEmail(token) {
    return http({
      url: '/auth/local/confirm-email',
      method: 'post',
      data: { token }
    });
  },

  refreshAccessToken() {
    return http({
      url: '/auth/local/refresh-token',
      method: 'post'
    });
  },

  async resetPasswordRequest(email) {
    return http({
      url: '/auth/local/reset-password',
      method: 'post',
      data: { email }
    });
  },
  async resetPassword({ token, password, confirm_password }) {
    return http({
      url: `/auth/local/reset-password/${token}`,
      method: 'post',
      data: { password, confirm_password }
    });
  },
  async acceptInvite({ token, password, confirm_password, language, agree_to_tc_pp }) {
    return http({
      url: `/auth/local/accept-invite/${token}`,
      method: 'post',
      data: { password, confirm_password, language, agree_to_tc_pp }
    });
  },
  async passwordReset({ email, old_password, password, confirm_password }) {
    return http({
      url: '/auth/local/reset-password',
      method: 'post',
      data: { email, old_password, password, confirm_password }
    });
  },

  async captchaRequest(form) {
    return http({
      url: '/auth/captcha',
      method: 'get',
      params: { form }
    });
  },

  async getOAuthToken({ client_name, code }) {
    return http({
      url: '/auth/oauth/token',
      method: 'get',
      params: { code, client_name }
    });
  }
};

import { mapMutations, mapState } from 'vuex';

export default {
  data() {
    return {
      filterTypes: {}
    };
  },
  computed: {
    ...mapState('navigation', ['routeFilters']),
    isFiltered() {
      if (this.calculateHash(this.DEFAULT_FILTERS) !== this.calculateHash(this.filters)) return true;
      return false;
    }
  },
  methods: {
    ...mapMutations('navigation', ['setRouteFilters']),
    calculateHash(filters) {
      return JSON.stringify(filters);
    },
    async loadFiltersFromRoute() {
      if (!this.$route.query) {
        return;
      }

      for (let k in this.filters) {
        if (typeof this.$route.query[k] === 'undefined') {
          this.filters[k] = this.DEFAULT_FILTERS[k];
        } else {
          this.filters[k] = this.$route.query[k];
        }
      }
    },
    async clearFilters() {
      await this.updateFilters(this.DEFAULT_FILTERS);
    },
    async updateFilters(filters) {
      for (let key in filters) {
        this.filters[key] = filters[key];
      }

      this.setRouteFilters({
        name: this.$route.name,
        filters
      });

      const routeQuery = {};
      for (let key in this.DEFAULT_FILTERS) {
        if (typeof filters[key] === 'undefined') {
          routeQuery[key] = this.$route.query[key];
        } else {
          routeQuery[key] = filters[key];
        }
      }

      this.$router.push({
        query: routeQuery
      });
    },
    savedQuery(name) {
      return this.routeFilters[name] || {};
    }
  }
};

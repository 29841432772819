import router from '@/router';
import AuthService from '@/services/AuthService';
import * as Sentry from '@sentry/browser';

import i18n from '@/i18n/i18n';
import { alertCenter } from '@/alert';
import UserService from '@/services/UserService';

const initialState = () => {
  return { status: {}, user: null, accessToken: null };
};

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    async changeConfig({ commit, state }, { key, value }) {
      commit('setConfigKeyValue', { key, value });
      await UserService.update('me', { ...state.user, password: undefined });
    },
    async loginSuccess({ dispatch, commit }, user) {
      commit('loginSuccess', user);
      await dispatch('i18n/changeLang', user.language, { root: true });
    },
    async login({ dispatch, commit }, { email, password, token, redirect }) {
      try {
        let response;

        if (email) {
          commit('loginRequest', { email });
          response = await AuthService.login(email, password);
          commit('loginSuccess', response.data);
          await dispatch('i18n/changeLang', response.data.language, { root: true });
        }

        if (token) {
          response = await AuthService.loginByToken(token);
          commit('loginSuccess', response.data);
          await dispatch('i18n/changeLang', response.data.language, { root: true });
          return true;
        }

        if (redirect) {
          router.push(redirect);
          return true;
        }

        if (response.data.global_role === 'ADMIN') {
          router.push({
            name: 'admin'
          });
        } else {
          router.push({
            name: 'home'
          });
        }
        return true;
      } catch (error) {
        commit('loginFailure', error);
        alertCenter.error(i18n.t(error));
        return false;
      }
    },
    async refreshToken({ state, commit }) {
      try {
        const response = await AuthService.refreshAccessToken();
        const accessToken = response.data.accessToken;
        commit('loginSuccess', response.data);
        return accessToken;
      } catch (err) {
        console.error('refreshToken failure', err);
        commit('loginFailure');
        return null;
      }
    },
    async signup({ dispatch, commit, rootState }, { firstname, lastname, email, password, agree_to_tc_pp, language, captcha }) {
      try {
        await dispatch('i18n/changeLang', language, { root: true });
        await AuthService.signup(firstname, lastname, email, password, agree_to_tc_pp, language, captcha);
        alertCenter.info(i18n.t('alert.weHaveSentConfirm'), {
          duration: 10000
        });
        await router.push('/login');
      } catch (error) {
        commit('loginFailure', error);
        alertCenter.error(error.message ? error.message : error);
      }
    },
    async confirmEmail({ dispatch, commit }, { token }) {
      try {
        await AuthService.confirmEmail(token);
        await router.push('/login');
        alertCenter.info(i18n.t('alert.yourEmailHasBeenConfirmed'), { duration: 10000 });
      } catch (error) {
        commit('Email confirmation failure', error);
        alertCenter.error(error.message ? error.message : error);
      }
    },
    async clearAuthentication({ commit }) {
      localStorage.setItem('user', null);
      localStorage.clear();
      await commit('afterLogout');
    },
    async logout({ dispatch, commit }, { skipCall } = { skipCall: false }) {
      try {
        if (!skipCall) {
          await AuthService.logout();
        }
      } finally {
        await dispatch('clearAuthentication');
        await dispatch('resetAll', {}, { root: true });
        const redirect = router.currentRoute.fullPath;
        await router.push({
          name: 'login',
          query: {
            redirect
          }
        });
      }
    },
    error() {
      router.push('/error');
    },
    async resetPasswordRequest({ rootState, dispatch }, { email }) {
      const response = await AuthService.resetPasswordRequest(email);
      if (response.status === 200) {
        alertCenter.info(response.data.message, { duration: 5000 });
      }
      await router.push('login');
    },
    async resetPassword({ dispatch }, { token, password, confirm_password }) {
      const response = await AuthService.resetPassword({
        token,
        password,
        confirm_password
      });
      if (response.status === 200) {
        alertCenter.info(response.data.message);
      }
      await router.push('login');
    },
    async acceptInvite({ dispatch }, { token, password, confirm_password, language, agree_to_tc_pp }) {
      const response = await AuthService.acceptInvite({
        token,
        password,
        confirm_password,
        language,
        agree_to_tc_pp
      });
      if (response.status === 200) {
        alertCenter.info(response.data.message);
      }
      await router.push('login');
    },
    async passwordReset({ dispatch }, { email, old_password, password, confirm_password }) {
      const response = await AuthService.passwordReset({
        email,
        old_password,
        password,
        confirm_password
      });
      if (response.status === 200) {
        alertCenter.info(response.data.message);
      }
      await router.push({
        name: 'profile'
      });
    }
  },
  mutations: {
    reset(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach(key => {
        state[key] = s[key];
      });
    },
    loginRequest(state, user) {
      state.status = { loggingIn: true };
      state.user = user;
    },
    loginSuccess(state, user) {
      state.status = { loggedIn: true };
      // login successful if there's a jwt token in the response
      // store user details and jwt token in local storage to keep user logged in between page refreshes

      if (user) {
        Sentry.configureScope(function(scope) {
          scope.setUser({ id: user.id, email: user.email, language: user.language });
        });
      }

      state.user = user;
      state.accessToken = user.accessToken;
    },
    loginFailure(state) {
      state.status = {};
      state.user = null;
      state.accessToken = null;
    },
    afterLogout(state) {
      state.status = {};
      state.user = null;
      state.accessToken = null;

      Sentry.configureScope(function(scope) {
        scope.setUser(null);
      });
    },
    setConfigKeyValue(state, { key, value }) {
      if (state.user?.id) {
        state.user.config[key] = value;
      }
      localStorage.setItem('user', JSON.stringify(state.user));
    }
  },
  getters: {
    user(state) {
      return state.user;
    },
    global_role(state) {
      if (!state.user) return 'ANONYMOUS';
      return state.user.global_role;
    },
    isAuthenticated: state => !!state.accessToken,
    isAdmin: state => state.user && state.user.global_role === 'ADMIN'
  }
};

function setTitle(breadcrumbs) {
  const el = document.head.querySelector('title');
  if (breadcrumbs.length > 1) {
    breadcrumbs = breadcrumbs.filter(b => !b.name)
    const theLast = breadcrumbs[breadcrumbs.length - 1];
    el.innerText = theLast.text ? theLast.text + ' - Legallab' : 'Legallab';
  } else {
    el.innerText = 'Legallab';
  }
}

export default {
  namespaced: true,
  state: {
    // array of breadcrumb objects, in the form of VueRouter
    // descriptors (see https://router.vuejs.org/api/#to)
    breadcrumbs: []
  },
  mutations: {
    set(state, breadcrumbs) {
      state.breadcrumbs = breadcrumbs;
      setTitle(state.breadcrumbs);
    },
    replace(state, payload) {
      const index = state.breadcrumbs.findIndex(breadcrumb => {
        return breadcrumb.text === payload.find;
      });

      if (index) {
        state.breadcrumbs.splice(index, 1, payload.replace);
      }
      setTitle(state.breadcrumbs);
    },
    reset(state) {
      state.breadcrumbs = [];
      setTitle(state.breadcrumbs);
    }
  }
};

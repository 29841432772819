import AuthService from "@/services/AuthService";

const initialState = () => {
    return { accessToken: {} };
};

export default {
    namespaced: true,
    state: initialState(),
    mutations: {
        reset(state) {
            // acquire initial state
            const s = initialState();
            Object.keys(s).forEach(key => {
                state[key] = s[key];
            });
        },
    },
    actions: {
        async getAccessToken({ dispatch, commit, state }, { client_name }) {
            if (state.accessToken[client_name]) {
                return state.accessToken[client_name];
            }

            const response = await AuthService.getOAuthToken({
                client_name: client_name,
                code: undefined // refresh
            });

            if (response.data.error) {
                console.warn(response.data.error);
            }
            if (response.data.access_token) {
                state.accessToken[client_name] = response.data.access_token;
                return state.accessToken[client_name];
            }

            const callbacks = {};

            const promise = new Promise((resolve, reject) => {
                callbacks['resolve'] = resolve;
                callbacks['reject'] = reject;
            });

            window['on_oauth'] = async function (params) {
                if (params.client_name !== client_name) {
                    delete window['on_oauth'];
                    callbacks['reject']();
                    return;
                }

                const response = await AuthService.getOAuthToken({
                    client_name: params.client_name,
                    code: params.code
                });
                if (response.data.error) {
                    console.warn(response.data.error);
                }

                const access_token = response.data.access_token;
                state.accessToken[client_name] = access_token;

                callbacks['resolve'](state.accessToken[client_name]);
                delete window['on_oauth'];
            }
            window.open(import.meta.env.VITE_API_BASE_URL + '/auth/oauth/authorize?client_name=' + client_name, 'oauth');

            return promise;
        }
    }
};

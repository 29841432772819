<template>
  <div class="admin-layout">
    <NavBar />
    <router-view />
  </div>
</template>

<script>
import NavBar from '@/layout/NavBar.vue';
export default {
  name: 'AdminNoSidebarLayout',
  components: {
    NavBar
  }
};
</script>
